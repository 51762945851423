<template>
    <path d="m393.7 325.8-101.8-101.8 101.8-101.8c12.5-12.5 12.5-32.8 0-45.3l-22.6-22.6c-12.5-12.5-32.8-12.5-45.3 0l-101.8 101.8-101.8-101.8c-12.5-12.5-32.8-12.5-45.3 0l-22.6 22.6c-12.5 12.5-12.5 32.8 0 45.3l101.8 101.8-101.8 101.8c-12.5 12.5-12.5 32.8 0 45.3l22.6 22.6c12.5 12.5 32.8 12.5 45.3 0l101.8-101.8 101.8 101.8c12.5 12.5 32.8 12.5 45.3 0l22.6-22.6c12.5-12.5 12.5-32.8 0-45.3z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 448, height: 448 });
    },
    emits: ['dimensions'],
};
</script>
